export default function BrandSuperligaAlt() {
  return (
    <svg
      width='245'
      height='287'
      viewBox='0 0 245 287'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61.2917 69.3069L52.1364 60.1291L42.9791 69.3069H35.1235V61.4301L44.2789 52.2523L35.1235 43.0745V35.1958H42.9791L52.1364 44.3736L61.2917 35.1958H69.1492V43.0726L59.9919 52.2523L69.1492 61.4301V69.3069H61.2917Z'
        fill='#FEC818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M201.703 69.3069L192.548 60.1272L183.391 69.3069H175.535V61.4301L184.692 52.2523L175.535 43.0745V35.1958H183.391L192.548 44.3736L201.703 35.1958H209.557V43.0726L200.404 52.2523L209.557 61.4301V69.3069H201.703Z'
        fill='#FEC818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61.2917 172.344L52.1364 163.167L42.9791 172.344H35.1235V164.468L44.2789 155.29L35.1235 146.112V138.235H42.9791L52.1364 147.413L61.2917 138.235H69.1492V146.112L59.9919 155.29L69.1492 164.468V172.344H61.2917Z'
        fill='#FEC818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M201.703 172.344L192.548 163.167L183.391 172.344H175.535V164.468L184.692 155.29L175.535 146.112V138.235H183.391L192.548 147.413L201.703 138.235H209.557V146.112L200.404 155.29L209.557 164.468V172.344H201.703Z'
        fill='#FEC818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M131.498 223.865H139.354V215.986L130.581 207.189L130.197 206.808L130.581 206.427L139.354 197.631V189.754H131.498L122.723 198.551L122.343 198.934L121.959 198.551L113.188 189.754H105.33V197.631L114.486 206.808L105.33 215.986V223.865H113.188L121.959 215.068L122.343 214.685L122.723 215.068L131.498 223.865Z'
        fill='#FEC818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M131.498 120.849L122.343 111.671L113.186 120.849H105.33V112.972L114.486 103.794L105.33 94.6164V86.7396H113.186L122.343 95.9175L131.498 86.7396H139.354V94.6164L130.197 103.794L139.354 112.972V120.849H131.498Z'
        fill='#FEC818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5292 197.375L122.335 273.483L234.045 197.447V10.6541H10.6394L10.5292 197.375ZM0 202.985L0.0133017 0H244.667L244.671 203.194L122.335 286.385L0 202.985Z'
        fill='#FEC818'
      />
    </svg>
  );
}
