export default function OSLiberec() {
  return (
    <svg
      width='144'
      height='144'
      viewBox='0 0 144 144'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M124.348 15.9753L71.2453 15.7187L71.3351 140.511L124.348 104.327V15.9753Z'
        fill='#3252A0'
      />
      <path
        d='M21.259 103.339L71.6813 137.574L122.052 103.365V19.2975H21.3103L21.259 103.339ZM71.6813 144L16 106.135V14H127.363V106.225L71.6813 144Z'
        fill='#3252A0'
      />
      <path
        d='M80.7881 76.1205C80.7881 76.1205 98.2582 70.2201 99.2331 41.3726C99.2331 41.3726 104.697 64.7431 84.4309 80.6227L80.7881 76.1205Z'
        fill='white'
      />
      <path
        d='M81.7887 84.6119C81.7887 84.6119 99.2717 78.7115 100.234 49.9153C100.234 49.9153 105.698 73.2986 85.4315 89.1654L81.7887 84.6119Z'
        fill='white'
      />
      <path
        d='M86.06 83.3548C86.06 83.3548 91.7679 91.9616 114.459 77.2364C114.459 77.2364 104.325 92.4362 85.4314 89.114L86.06 83.3548Z'
        fill='white'
      />
    </svg>
  );
}
