export default function OSJihlava() {
  return (
    <svg
      width='278'
      height='141'
      viewBox='0 0 278 141'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_404_7)'>
        <path
          d='M162.7 10.0325V33.3329H156.819V19.7721L151.721 27.8119H149.662L144.566 19.7388V33.3329H138.685V10.0325H144.371L150.709 20.034L157.014 10.0325H162.7Z'
          fill='#005384'
        />
        <path
          d='M181.746 28.3333H173.514L172.012 33.3329H165.999L173.349 10.0325H181.944L189.294 33.3329H183.251L181.746 28.3333ZM180.245 23.3004L177.662 14.6702L175.044 23.298L180.245 23.3004Z'
          fill='#005384'
        />
        <path
          d='M208.735 28.3333V33.3329H192.562V10.0325H198.443V28.3333H208.735Z'
          fill='#005384'
        />
        <path
          d='M220.952 25.3836V33.3258H215.04V25.3836L206.71 10.0253H213.243L218.003 18.9794L222.74 10.0253H229.276L220.952 25.3836ZM215.171 8.03265L213.569 3.91396L223.468 -0.00714111L225.07 4.10918L215.171 8.03265Z'
          fill='#005384'
        />
        <path
          d='M144.633 45.7914V50.3981H153.911V55.3977H144.633V64.0922H138.751V40.7918H155.284V45.7914H144.633Z'
          fill='#005384'
        />
        <path
          d='M177.793 46.2485V58.6284C177.793 62.4519 175.801 64.4136 171.881 64.4136H164.528C160.608 64.4136 158.616 62.4519 158.616 58.6284V46.2485C158.616 42.425 160.608 40.4632 164.528 40.4632H171.881C175.801 40.4632 177.793 42.425 177.793 46.2485ZM170.115 45.4628H166.292C164.954 45.4628 164.495 45.889 164.495 47.196V57.6856C164.495 58.9617 164.954 59.4188 166.292 59.4188H170.115C171.455 59.4188 171.912 58.9617 171.912 57.6856V47.196C171.912 45.889 171.455 45.4628 170.115 45.4628Z'
          fill='#005384'
        />
        <path
          d='M200.143 45.7914H193.509V64.0922H187.628V45.7914H180.997V40.7918H200.143V45.7914Z'
          fill='#005384'
        />
        <path
          d='M222.097 56.4762V58.307C222.097 62.1305 220.072 64.0922 216.183 64.0922H203.406V40.7894H215.659C219.579 40.7894 221.609 42.7511 221.609 46.5746V47.915C221.609 49.6458 220.988 50.8243 219.355 51.8694C221.348 52.7503 222.1 54.124 222.1 56.4762M213.898 45.7247H209.292V49.8434H213.898C215.271 49.8434 215.728 49.4506 215.728 48.1769V47.4245C215.728 46.1508 215.271 45.7247 213.898 45.7247ZM216.218 56.4381C216.218 55.1287 215.761 54.7049 214.388 54.7049H209.292V59.1497H214.388C215.761 59.1497 216.218 58.7236 216.218 57.4166V56.4381Z'
          fill='#005384'
        />
        <path
          d='M239.446 59.0902H231.213L229.709 64.0898H223.697L231.049 40.787H239.641L246.994 64.0898H240.948L239.446 59.0902ZM237.942 54.0573L235.362 45.4319L232.744 54.0573H237.942Z'
          fill='#005384'
        />
        <path
          d='M266.432 59.0926V64.0922H250.259V40.7918H256.141V59.0926H266.432Z'
          fill='#005384'
        />
        <path
          d='M153.387 71.5487V89.4044C153.387 93.2279 151.395 95.1872 147.475 95.1872H143.162C139.208 95.1872 137.211 93.2279 137.211 89.4044C137.2 89.2512 137.211 89.0972 137.245 88.9473L143.093 88.233V88.4711C143.093 89.7448 143.552 90.2019 144.923 90.2019H145.706C147.046 90.2019 147.506 89.7448 147.506 88.4711V76.5674H140.646V71.5678L153.387 71.5487Z'
          fill='#005384'
        />
        <path d='M164.171 71.5487H158.29V94.8491H164.171V71.5487Z' fill='#005384' />
        <path
          d='M188.673 71.5487V94.8491H182.791V85.569H174.951V94.8491H169.07V71.5487H174.951V80.5361H182.791V71.5487H188.673Z'
          fill='#005384'
        />
        <path
          d='M209.747 89.8496V94.8491H193.573V71.5487H199.455V89.8496H209.747Z'
          fill='#005384'
        />
        <path
          d='M227.193 89.8496H218.96L217.456 94.8492H211.446L218.796 71.5487H227.388L234.741 94.8492H228.695L227.193 89.8496ZM225.691 84.8167L223.109 76.1888L220.491 84.8167H225.691Z'
          fill='#005384'
        />
        <path
          d='M256.141 71.5487L249.084 94.8491H240.948L233.86 71.5487H239.87L245.002 89.1306L250.131 71.5487H256.141Z'
          fill='#005384'
        />
        <path
          d='M270.452 89.8496H262.217L260.715 94.8492H254.703L262.055 71.5487H270.648L278 94.8492H271.954L270.452 89.8496ZM268.948 84.8167L266.368 76.1888L263.75 84.8167H268.948Z'
          fill='#005384'
        />
        <path
          d='M117.818 99.613L60.1206 138.967V59.0736H115.688L117.944 59.2021L117.818 99.613Z'
          fill='#C3171B'
        />
        <path
          d='M104.387 28.3L98.0436 15.6106L93.15 25.4002L88.2564 15.6106L83.0439 26.0407L77.8313 15.6106L71.4882 28.3H104.387Z'
          fill='#C3171B'
        />
        <path
          d='M93.15 42.5036L88.2564 32.7139L83.0439 43.144L77.8313 32.7139L71.4882 45.4033H104.387L98.0436 32.7139L93.15 42.5036Z'
          fill='#C3171B'
        />
        <path
          d='M120.191 0.75946H0.00488281V101.113L60.1205 141L120.243 101.113L120.191 0.75946ZM115.721 98.6869L60.1324 135.567L4.53195 98.6655V61.4591V59.0783H60.1205V5.27336H115.671L115.721 98.6774'
          fill='#005384'
        />
      </g>
      <defs>
        <clipPath id='clip0_404_7'>
          <rect width='278' height='141' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
