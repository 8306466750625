import { HTMLAttributes } from 'react';

export default function BrandCNP(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 124 66' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.5932 1.39462L63.0671 0.172363L65.5914 1.2551L68.1031 0.172363L68.5874 1.39545L65.5909 2.61227L62.5932 1.39462ZM64.0666 13.8352C62.4663 13.8352 61.721 13.1117 61.721 11.5582V6.29783C61.721 4.74432 62.4663 4.02041 64.0666 4.02041H67.0731C68.6805 4.02041 69.4308 4.73722 69.4337 6.27569L67.5474 6.50586C67.5073 5.84168 67.1666 5.56724 66.4022 5.56724H64.7522C63.9359 5.56724 63.6028 5.88429 63.6028 6.66167V11.1944C63.6028 11.9713 63.9359 12.2888 64.7522 12.2888H66.4022C67.1666 12.2888 67.5073 12.0143 67.5474 11.3506L69.4337 11.5808C69.4308 13.1188 68.6805 13.8352 67.0731 13.8352H64.0666Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M71.1285 13.6951V4.16064H77.7497V5.70705H73.0098V8.03543H77.1627V9.58184H73.0098V12.1487H77.7497V13.6951H71.1285Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M81.5698 13.8353C79.9941 13.8353 79.2267 13.0976 79.2238 11.5808L81.1097 11.3507C81.1502 12.0144 81.4909 12.2889 82.2549 12.2889H83.9329C84.7496 12.2889 85.0827 11.9714 85.0827 11.1945V10.9426C85.0827 10.1877 84.6711 10.0411 83.7976 9.90662L81.4742 9.57077C79.8434 9.33393 79.2656 8.7888 79.2656 7.48718V6.29793C79.2656 4.744 80.0154 4.02051 81.6258 4.02051H84.5621C86.1699 4.02051 86.9193 4.73732 86.9223 6.27537L85.0364 6.50595C84.9963 5.84136 84.6556 5.56733 83.8912 5.56733H82.3251C81.4997 5.56733 81.1469 5.89441 81.1469 6.66176V6.87146C81.1469 7.62712 81.5239 7.75744 82.4482 7.8932L84.7696 8.22947C86.4104 8.4659 86.964 8.99891 86.964 10.3411V11.5583C86.964 13.1118 86.2188 13.8353 84.6185 13.8353H81.5698Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M94.7479 13.6951L91.8955 9.63781H90.5932V13.6951H88.712V4.16064H90.5932V8.0914H91.8838L94.5541 4.16064H96.7264L93.555 8.85959L96.9285 13.6951H94.7479Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M101.192 1.5063L104.924 0L105.41 1.22685L101.668 2.74527L101.192 1.5063ZM101.788 13.6954V10.4405L98.3278 4.16051H100.4L102.75 8.44925L105.072 4.16051H107.144L103.683 10.4405V13.6954H101.788Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M67.863 28.863L63.7142 22.1736V28.863H61.8329V19.3281H63.7393L67.9018 26.0154V19.3281H69.7831V28.863H67.863Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.0292 23.7071H86.5493C87.3655 23.7071 87.6987 23.3896 87.6987 22.6127V21.969C87.6987 21.192 87.3655 20.8745 86.5493 20.8745H84.0292V23.7071ZM88.2248 28.863L85.6521 25.2535H84.0292V28.863H82.1475V19.3281H87.2348C88.8352 19.3281 89.5804 20.0512 89.5804 21.6055V22.9899C89.5804 24.1883 89.1107 24.8947 88.1446 25.1491L87.849 25.2268L90.4046 28.863H88.2248Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M94.6867 20.7344C93.87 20.7344 93.5369 21.0514 93.5369 21.8288V26.3619C93.5369 27.1389 93.87 27.4564 94.6867 27.4564H96.3363C97.1533 27.4564 97.4865 27.1389 97.4865 26.3619V21.8288C97.4865 21.0514 97.1533 20.7344 96.3363 20.7344H94.6867ZM94.0012 29.0028C92.4008 29.0028 91.6556 28.2793 91.6556 26.7258V21.465C91.6556 19.9111 92.4008 19.188 94.0012 19.188H97.0081C98.618 19.188 99.3678 19.9111 99.3678 21.465V26.7258C99.3678 28.2793 98.618 29.0028 97.0081 29.0028H94.0012Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M103.056 27.3164H105.576C106.392 27.3164 106.725 26.9989 106.725 26.222V21.9687C106.725 21.1918 106.392 20.8743 105.576 20.8743H103.056V27.3164ZM101.174 28.8628V19.3279H106.261C107.862 19.3279 108.607 20.051 108.607 21.6053V26.5854C108.607 28.1393 107.862 28.8628 106.261 28.8628H101.174Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M116.423 28.863L112.274 22.1736V28.863H110.393V19.3281H112.299L116.462 26.0154V19.3281H118.343V28.863H116.423Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M119.632 16.6736L123.364 15.1677L123.85 16.3946L120.108 17.913L119.632 16.6736ZM120.242 28.8627H122.123V19.3278H120.242V28.8627Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.7422 38.9446H66.2347C67.0509 38.9446 67.3841 38.6271 67.3841 37.8502V37.1367C67.3841 36.3597 67.0509 36.0423 66.2347 36.0423H63.7422V38.9446ZM61.8605 44.0308V34.4958H66.9057C68.516 34.4958 69.2654 35.2193 69.2654 36.7733V38.214C69.2654 39.7675 68.516 40.491 66.9057 40.491H63.7422V44.0308H61.8605Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M73.7844 35.9021C72.9682 35.9021 72.635 36.2196 72.635 36.9965V41.5297C72.635 42.307 72.9682 42.6241 73.7844 42.6241H75.4348C76.251 42.6241 76.5842 42.307 76.5842 41.5297V36.9965C76.5842 36.2196 76.251 35.9021 75.4348 35.9021H73.7844ZM73.0989 44.1705C71.4985 44.1705 70.7533 43.447 70.7533 41.8935V36.6327C70.7533 35.0788 71.4985 34.3557 73.0989 34.3557H76.1058C77.7161 34.3557 78.4659 35.0788 78.4659 36.6327V41.8935C78.4659 43.447 77.7161 44.1705 76.1058 44.1705H73.0989Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.2289 44.0305V39.9736H82.1532V44.0305H80.2719V34.4956H82.1532V38.4268H86.2289V34.4956H88.1106V44.0305H86.2289Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M102.376 38.8748H104.896C105.712 38.8748 106.045 38.5574 106.045 37.7804V37.1367C106.045 36.3597 105.712 36.0423 104.896 36.0423H102.376V38.8748ZM106.572 44.0308L103.999 40.4212H102.376V44.0308H100.494V34.4958H105.581C107.181 34.4958 107.927 35.2193 107.927 36.7733V38.158C107.927 39.356 107.457 40.062 106.491 40.3164L106.195 40.3941L108.751 44.0308H106.572Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M74.4559 24.9913H77.3375L75.8888 20.4428L74.4559 24.9913ZM78.5588 28.8632L77.8402 26.6058H73.939L73.2197 28.8632H71.1801L74.3152 19.3279H77.4778L80.5995 28.8632H78.5588Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M74.4751 16.6484L78.2072 15.1421L78.6936 16.3694L74.9515 17.8878L74.4751 16.6484Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M92.7451 40.1588H95.6268L94.178 35.6103L92.7451 40.1588ZM96.848 44.0307L96.1295 41.7733H92.2283L91.5089 44.0307H89.469L92.6044 34.4954H95.7671L98.8884 44.0307H96.848Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M92.7638 31.8159L96.4964 30.3101L96.9824 31.5369L93.2406 33.0553L92.7638 31.8159Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4667 18.2052L11.4551 16.1927L9.44314 18.2052H7.71716V16.478L9.72872 14.4654L7.71716 12.4528V10.7251H9.44314L11.4551 12.7377L13.4667 10.7251H15.1931V12.4524L13.1811 14.4654L15.1931 16.478V18.2052H13.4667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.3171 18.2052L42.3055 16.1922L40.2935 18.2052H38.5676V16.478L40.5795 14.4654L38.5676 12.4528V10.7251H40.2935L42.3055 12.7377L44.3171 10.7251H46.0426V12.4524L44.0315 14.4654L46.0426 16.478V18.2052H44.3171Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4667 40.8003L11.4551 38.7877L9.44314 40.8003H7.71716V39.073L9.72872 37.0604L7.71716 35.0478V33.3206H9.44314L11.4551 35.3331L13.4667 33.3206H15.1931V35.0478L13.1811 37.0604L15.1931 39.073V40.8003H13.4667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.3171 40.8003L42.3055 38.7877L40.2935 40.8003H38.5676V39.073L40.5795 37.0604L38.5676 35.0478V33.3206H40.2935L42.3055 35.3331L44.3171 33.3206H46.0426V35.0478L44.0315 37.0604L46.0426 39.073V40.8003H44.3171Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.892 52.0978H30.618V50.3701L28.6904 48.4411L28.606 48.3575L28.6904 48.274L30.618 46.345V44.6177H28.892L26.964 46.5467L26.8805 46.6307L26.7961 46.5467L24.8689 44.6177H23.1425V46.345L25.1541 48.3575L23.1425 50.3701V52.0978H24.8689L26.7961 50.1688L26.8805 50.0848L26.964 50.1688L28.892 52.0978Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.892 29.5078L26.8805 27.4952L24.8685 29.5078H23.1425V27.7805L25.1541 25.7679L23.1425 23.7554V22.0281H24.8685L26.8805 24.0407L28.892 22.0281H30.618V23.7554L28.606 25.7679L30.618 27.7805V29.5078H28.892Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.31341 46.2889L26.8788 62.9785L51.4228 46.3047V5.3434H2.33763L2.31341 46.2889ZM0 47.5191L0.00292256 3.00708H53.7567L53.7575 47.565L26.8788 65.8077L0 47.5191Z'
        fill='#DDDDDD'
      />
    </svg>
  );
}
