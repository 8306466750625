export default function OSPlzenAlt() {
  return (
    <svg
      width='240'
      height='281'
      viewBox='0 0 240 281'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1507_7)'>
        <path d='M240 0H119.959V116.721H240V0Z' fill='#1EA538' />
        <path
          d='M119.959 116.721L0.00195312 116.722V198.882H119.959L119.959 116.721Z'
          fill='#1EA538'
        />
        <path d='M33.6635 222.175H119.96V281L33.6635 222.175Z' fill='#1EA538' />
        <path d='M119.959 138.747H0.00195312V162.041H119.959V138.747Z' fill='white' />
        <path
          d='M119.959 198.881V222.175H33.6622L0.00195312 199.219V198.881H119.959Z'
          fill='#FEC818'
        />
        <path d='M119.999 0H0V116.721H119.999V0Z' fill='#BB2E1C' />
        <path d='M120.002 116.594V280.957L239.958 199.345V116.594H120.002Z' fill='#BB2E1C' />
      </g>
      <defs>
        <clipPath id='clip0_1507_7'>
          <rect width='240' height='281' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
