export default function OSPKFL() {
  return (
    <svg
      width='580'
      height='304'
      viewBox='0 0 580 304'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1507_18)'>
        <path
          d='M189.453 107.98C189.453 112.684 188.626 117.287 186.971 121.788C185.317 126.288 182.957 130.056 179.893 133.121C175.696 137.265 171.008 140.392 165.84 142.51C160.672 144.628 154.238 145.687 146.548 145.687H129.625V179.932H102.438V74.8036H147.191C153.891 74.8036 159.549 75.3841 164.155 76.5347C168.761 77.6854 172.826 79.4165 176.37 81.728C180.619 84.5079 183.866 88.0618 186.103 92.3895C188.35 96.7172 189.463 101.921 189.463 107.99L189.453 107.98ZM161.336 108.58C161.336 105.617 160.529 103.071 158.926 100.933C157.323 98.7945 155.454 97.3078 153.329 96.4627C150.5 95.3324 147.732 94.7112 145.046 94.5992C142.36 94.4872 138.765 94.4261 134.282 94.4261H129.604V125.84H137.397C142.023 125.84 145.833 125.555 148.835 124.995C151.838 124.435 154.351 123.305 156.373 121.614C158.119 120.107 159.386 118.315 160.162 116.228C160.938 114.14 161.326 111.594 161.326 108.58H161.336Z'
          fill='#1D1D1B'
        />
        <path
          d='M307.148 179.922H273.731L242.214 137.846L235.902 145.615V179.922H208.715V74.7935H235.902V122.419L273.568 74.7935H305.085L263.875 123.437L307.138 179.922H307.148Z'
          fill='#1D1D1B'
        />
        <path
          d='M396.084 95.1287H347.571V114.69H392.54V135.025H347.571V179.932H320.517V74.8036H396.073V95.1389L396.084 95.1287Z'
          fill='#1D1D1B'
        />
        <path
          d='M490.912 179.922H414.855V74.7935H442.042V159.586H490.902V179.922H490.912Z'
          fill='#1D1D1B'
        />
        <path
          d='M143.29 227.567V208.434L137.101 221.458H135.334L129.186 208.434V227.567H126.418V205.358H130.462L136.406 217.721L142.146 205.358H146.262V227.567H143.29Z'
          fill='#1D1D1B'
        />
        <path
          d='M177.953 227.567L175.767 221.376H166.136L163.951 227.567H160.948L169.057 205.358H173.01L181.119 227.567H177.953ZM174.858 218.841L170.957 207.935L167.035 218.841H174.858Z'
          fill='#1D1D1B'
        />
        <path d='M209.889 227.567H195.795V205.358H198.757V224.94H209.889V227.567Z' fill='#1D1D1B' />
        <path
          d='M239.926 205.358L232.082 217.833V227.578H229.12V218.148L221.246 205.369H224.524L230.611 215.307L236.76 205.369H239.916L239.926 205.358ZM235.156 197.009L231.071 202.589H228.845L231.52 197.009H235.156Z'
          fill='#1D1D1B'
        />
        <path
          d='M290.613 207.986H279.348V214.248H289.03V216.875H279.348V227.567H276.386V205.358H290.613V207.986Z'
          fill='#1D1D1B'
        />
        <path
          d='M321.906 207.904C322.815 208.902 323.51 210.114 323.99 211.56C324.47 213.006 324.715 214.635 324.715 216.468C324.715 218.301 324.47 219.94 323.979 221.386C323.489 222.832 322.795 224.044 321.906 225.001C320.987 226.009 319.904 226.763 318.648 227.272C317.392 227.781 315.972 228.036 314.359 228.036C312.745 228.036 311.366 227.781 310.09 227.262C308.813 226.743 307.72 225.989 306.821 225.011C305.923 224.034 305.238 222.822 304.748 221.386C304.258 219.951 304.023 218.321 304.023 216.478C304.023 214.635 304.258 213.047 304.738 211.611C305.218 210.175 305.912 208.943 306.832 207.925C307.71 206.947 308.803 206.204 310.1 205.684C311.397 205.165 312.816 204.91 314.359 204.91C315.901 204.91 317.392 205.175 318.658 205.695C319.935 206.214 321.007 206.957 321.896 207.925L321.906 207.904ZM321.641 216.468C321.641 213.586 320.997 211.356 319.7 209.798C318.403 208.23 316.636 207.446 314.389 207.446C312.142 207.446 310.345 208.23 309.058 209.798C307.771 211.366 307.118 213.586 307.118 216.468C307.118 219.35 307.771 221.61 309.089 223.158C310.406 224.706 312.173 225.48 314.389 225.48C316.605 225.48 318.362 224.706 319.68 223.158C320.997 221.61 321.651 219.38 321.651 216.468H321.641Z'
          fill='#1D1D1B'
        />
        <path
          d='M347.909 207.986V227.567H344.947V207.986H336.991V205.358H355.875V207.986H347.909Z'
          fill='#1D1D1B'
        />
        <path
          d='M386.963 220.735C386.963 221.834 386.749 222.812 386.33 223.657C385.911 224.502 385.35 225.195 384.635 225.745C383.797 226.396 382.878 226.865 381.877 227.15C380.876 227.435 379.6 227.567 378.057 227.567H370.163V205.358H376.76C378.384 205.358 379.6 205.42 380.406 205.542C381.213 205.664 381.99 205.908 382.725 206.285C383.542 206.713 384.134 207.263 384.502 207.935C384.87 208.607 385.053 209.411 385.053 210.348C385.053 211.407 384.788 212.303 384.247 213.036C383.705 213.78 382.99 214.37 382.092 214.818V214.941C383.593 215.246 384.788 215.908 385.656 216.916C386.524 217.924 386.953 219.197 386.953 220.745L386.963 220.735ZM381.979 210.725C381.979 210.185 381.887 209.737 381.714 209.371C381.54 209.004 381.244 208.709 380.846 208.474C380.376 208.21 379.804 208.037 379.14 207.975C378.476 207.914 377.649 207.874 376.658 207.874H373.125V214.289H376.954C377.884 214.289 378.619 214.238 379.171 214.146C379.722 214.055 380.233 213.851 380.692 213.556C381.152 213.26 381.489 212.873 381.683 212.395C381.877 211.926 381.979 211.366 381.979 210.715V210.725ZM383.879 220.857C383.879 219.961 383.746 219.248 383.47 218.729C383.195 218.209 382.715 217.751 382 217.384C381.52 217.14 380.938 216.977 380.253 216.896C379.569 216.824 378.742 216.784 377.761 216.784H373.104V225.052H377.026C378.323 225.052 379.385 224.981 380.212 224.849C381.04 224.716 381.714 224.472 382.245 224.115C382.807 223.728 383.215 223.28 383.47 222.792C383.726 222.303 383.858 221.651 383.858 220.867L383.879 220.857Z'
          fill='#1D1D1B'
        />
        <path
          d='M416.888 227.567L414.702 221.376H405.071L402.885 227.567H399.883L407.992 205.358H411.944L420.054 227.567H416.888ZM413.793 218.841L409.892 207.935L405.97 218.841H413.793Z'
          fill='#1D1D1B'
        />
        <path d='M448.814 227.567H434.72V205.358H437.681V224.94H448.814V227.567Z' fill='#1D1D1B' />
        <path d='M51.7802 257.423V13.8182H0V257.423H51.7802Z' fill='#E8C85D' />
        <path d='M0.0816986 51.6273H219.387V0H0.0816986V51.6273Z' fill='#E8C85D' />
        <path d='M0.0510607 304H219.356V252.373H0.0510607V304Z' fill='#E8C85D' />
        <path d='M579.888 295.141V4.90815H528.107V295.141H579.888Z' fill='#E8C85D' />
        <path d='M360.695 51.8513H580V0.224025H360.695V51.8513Z' fill='#E8C85D' />
        <path d='M360.664 303.888H579.969V252.261H360.664V303.888Z' fill='#E8C85D' />
      </g>
      <defs>
        <clipPath id='clip0_1507_18'>
          <rect width='580' height='304' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
