import { SVGAttributes } from 'react';

export default function OSKarlovyVary(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width='144'
      height='144'
      viewBox='0 0 144 144'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M127.224 69.2294L127.181 107.094L71.6455 144L71.624 67.866' fill='white' />
      <path
        d='M94.6153 128.74L71.624 144V126.881H71.6458C73.3887 126.881 74.1915 127.575 75.2909 128.53C76.5707 129.644 78.1549 131.025 81.1633 131.025C84.1719 131.025 85.7634 129.644 87.0432 128.537C88.1497 127.575 88.9525 126.881 90.7101 126.881C92.4748 126.881 93.2703 127.575 94.3767 128.537C94.4563 128.602 94.536 128.675 94.6153 128.74Z'
        fill='#BA2B25'
      />
      <path
        d='M115.661 114.774L97.747 126.657C97.3636 126.397 96.995 126.086 96.5827 125.731C95.3097 124.617 93.7189 123.244 90.7101 123.244C87.7012 123.244 86.1175 124.617 84.8375 125.731C83.731 126.693 82.928 127.388 81.1635 127.388C79.4062 127.388 78.6036 126.693 77.4967 125.731C76.2241 124.625 74.64 123.244 71.646 123.244H71.624V111.505H71.646C73.3887 111.513 74.1918 112.207 75.2911 113.162C76.571 114.275 78.1551 115.65 81.1635 115.65C84.1721 115.65 85.7634 114.275 87.0435 113.162C88.1499 112.2 88.9525 111.505 90.7101 111.505C92.4748 111.505 93.2703 112.2 94.3767 113.162C95.6568 114.275 97.2483 115.65 100.256 115.65C103.265 115.65 104.849 114.275 106.129 113.162C107.236 112.2 108.031 111.505 109.796 111.505C111.561 111.505 112.363 112.2 113.47 113.162C114.085 113.697 114.772 114.297 115.661 114.774Z'
        fill='#BA2B25'
      />
      <path
        d='M127.233 95.4932V107.094C127.233 107.094 121.599 112.012 118.858 112.012C117.086 112.012 116.775 111.317 115.676 110.355C114.396 109.249 112.805 107.868 109.796 107.868C106.787 107.868 105.196 109.249 103.923 110.355C102.817 111.317 102.014 112.012 100.256 112.012C98.492 112.012 97.6892 111.317 96.5827 110.355C95.3097 109.249 93.7187 107.868 90.7101 107.868C87.7012 107.868 86.1173 109.249 84.8372 110.355C83.731 111.317 82.928 112.012 81.1633 112.012C79.4059 112.012 78.6033 111.317 77.4967 110.355C76.2239 109.249 74.6397 107.875 71.6458 107.868H71.624V95.2184H71.6458C73.3887 95.2184 74.1915 95.9128 75.2909 96.8673C76.5707 97.981 78.1549 99.3625 81.1633 99.3625C84.1721 99.3625 85.7634 97.981 87.0432 96.8673C88.1497 95.9054 88.9525 95.2184 90.7101 95.2184C92.4748 95.2184 93.2703 95.9054 94.3767 96.8673C95.6568 97.981 97.2481 99.3625 100.256 99.3625C103.265 99.3625 104.849 97.981 106.129 96.8673C107.236 95.9054 108.031 95.2184 109.796 95.2184C111.561 95.2184 112.363 95.9054 113.47 96.8673C114.743 97.981 116.334 99.3625 119.343 99.3625C122.351 99.3625 123.935 97.981 125.215 96.8673C125.902 96.2743 126.473 95.7826 127.233 95.4932Z'
        fill='#BA2B25'
      />
      <path
        d='M127.233 79.5099V91.7468C125.259 92.1374 124.029 93.1788 123.009 94.0684C121.903 95.0305 121.1 95.7246 119.343 95.7246C117.578 95.7246 116.775 95.0305 115.676 94.0684C114.396 92.9546 112.805 91.5734 109.796 91.5734C106.787 91.5734 105.196 92.9546 103.923 94.0684C102.817 95.0305 102.014 95.7246 100.256 95.7246C98.492 95.7246 97.6892 95.0305 96.5827 94.0684C95.3097 92.9546 93.7187 91.5734 90.7101 91.5734C87.7012 91.5734 86.1173 92.9546 84.8372 94.0684C83.731 95.0305 82.928 95.7246 81.1633 95.7246C79.4059 95.7246 78.6033 95.0305 77.4967 94.0684C76.2239 92.9619 74.6397 91.5805 71.6458 91.5734H71.624V79.2278H71.6458C73.3887 79.2351 74.1915 79.9293 75.2909 80.884C76.5707 81.9978 78.1549 83.379 81.1633 83.379C84.1721 83.379 85.7634 81.9978 87.0432 80.884C88.1497 79.9222 88.9525 79.2278 90.7101 79.2278C92.4748 79.2278 93.2703 79.9222 94.3767 80.884C95.6568 81.9978 97.2481 83.379 100.256 83.379C103.265 83.379 104.849 81.9978 106.129 80.884C107.236 79.9222 108.031 79.2278 109.796 79.2278C111.561 79.2278 112.363 79.9222 113.47 80.884C114.743 81.9978 116.334 83.379 119.343 83.379C122.351 83.379 123.935 81.9978 125.215 80.884C125.902 80.291 126.473 79.7993 127.233 79.5099Z'
        fill='#BA2B25'
      />
      <path
        d='M127.233 67.8662V75.7566C125.259 76.1545 124.029 77.1957 123.009 78.0855C121.903 79.0473 121.1 79.7417 119.343 79.7417C117.578 79.7417 116.775 79.0473 115.676 78.0855C114.396 76.9715 112.805 75.5903 109.796 75.5903C106.787 75.5903 105.196 76.9715 103.923 78.0855C102.817 79.0473 102.014 79.7417 100.256 79.7417C98.492 79.7417 97.6892 79.0473 96.5827 78.0855C95.3097 76.9715 93.7187 75.5903 90.7101 75.5903C87.7012 75.5903 86.1173 76.9715 84.8372 78.0855C83.731 79.0473 82.928 79.7417 81.1633 79.7417C79.4059 79.7417 78.6033 79.0473 77.4967 78.0855C76.2239 76.9788 74.6397 75.5976 71.6458 75.5903H71.624V67.8662H127.233Z'
        fill='#BA2B25'
      />
      <path d='M71.6235 67.866V144L71.58 143.971L16 107.094V67.866H71.6235Z' fill='#3454AD' />
      <path d='M127.232 14.0003V67.8589H71.6231V67.8662H71.6016V14.0003H127.232Z' fill='#3454AD' />
      <path d='M71.6196 67.8657H16.0039V14.0001H71.6196V67.8657Z' fill='#BA2B25' />
    </svg>
  );
}
