export default function OSMost() {
  return (
    <svg
      width='144'
      height='144'
      viewBox='0 0 144 144'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M87.956 51.2051C87.956 56.628 87.956 62.0488 87.956 67.4675C87.9335 68.0788 88.0085 68.6897 88.1782 69.2773C88.376 69.9851 88.7682 70.6232 89.3103 71.1194C89.8525 71.6155 90.5228 71.9497 91.2453 72.084C91.9825 72.2827 92.7594 72.2804 93.4953 72.0771C94.2313 71.8739 94.8993 71.4772 95.43 70.9283C95.7126 70.6124 95.9281 70.2424 96.0635 69.8407C96.1988 69.439 96.2512 69.014 96.2174 68.5915C96.2174 65.4165 93.9377 63.5877 90.8199 64.2798C89.6515 64.5402 89.6515 64.5402 89.6515 63.3654C89.6515 62.1907 89.6515 60.9206 89.6515 59.6951C89.6515 59.3458 89.6959 59.1426 90.115 59.0156C95.6078 57.3456 101.634 61.2762 102.18 66.9532C102.377 68.8492 102.07 70.7635 101.291 72.5031C100.756 73.8111 99.8888 74.9567 98.775 75.8265C97.6613 76.6964 96.3397 77.2603 94.941 77.4625C92.3856 77.9556 89.7426 77.7111 87.321 76.7577C85.8988 76.2421 84.6527 75.3323 83.7284 74.1348C82.8041 72.9372 82.2397 71.5013 82.1013 69.9949C82.0091 69.2131 81.9709 68.426 81.987 67.639V51.2051H87.956Z'
        fill='#2DB200'
      />
      <path
        d='M133.956 51.3574V72.5983C133.956 73.9699 133.956 75.3352 133.956 76.7068C133.956 77.3037 133.759 77.4307 133.213 77.418C131.759 77.3799 130.298 77.418 128.844 77.418C128.012 77.418 128.012 77.418 128.012 76.5417C128.012 68.4983 128.012 60.4549 128.012 52.4115C128.012 52.0623 128.012 51.713 127.98 51.3638L133.956 51.3574Z'
        fill='#2DB200'
      />
      <path
        d='M124.342 71.6964C124.342 73.3918 124.342 75.081 124.342 76.7764C124.342 77.259 124.228 77.4114 123.707 77.4114C122.164 77.4114 120.614 77.367 119.071 77.4114C118.436 77.4114 118.366 77.1765 118.373 76.6431C118.373 74.1412 118.373 71.6329 118.373 69.131C118.373 68.896 118.373 68.6611 118.373 68.4325C118.316 65.5495 116.392 63.9239 113.534 64.324C111.356 64.6351 110.035 66.2861 110.143 68.5785C110.139 69.0783 110.235 69.5738 110.425 70.0359C110.616 70.498 110.897 70.9173 111.252 71.2689C111.607 71.6205 112.029 71.8974 112.493 72.0831C112.957 72.2689 113.454 72.3598 113.953 72.3504C114.825 72.4369 115.704 72.3329 116.531 72.0456C116.817 71.9313 116.963 71.9885 116.963 72.3314C116.963 73.6988 116.963 75.0683 116.963 76.4399C116.974 76.5723 116.943 76.7047 116.875 76.8187C116.806 76.9326 116.704 77.0222 116.582 77.0749C116.186 77.3122 115.759 77.4918 115.312 77.6083C114.163 77.8903 112.97 77.9402 111.801 77.7551C110.633 77.57 109.514 77.1536 108.508 76.5302C107.503 75.9069 106.632 75.0892 105.947 74.1251C105.261 73.1609 104.775 72.0697 104.517 70.9153C103.457 66.4703 104.898 62.3745 108.918 60.0123C110.899 58.8502 113.064 58.7423 115.268 58.9391C117.252 59.0924 119.168 59.729 120.849 60.7934C121.937 61.4543 122.832 62.388 123.447 63.502C124.062 64.6159 124.375 65.8712 124.355 67.1434C124.355 68.6547 124.355 70.1724 124.355 71.6837L124.342 71.6964Z'
        fill='#2DB200'
      />
      <path
        d='M72.4552 54.8052C73.1537 54.8052 73.8522 54.8052 74.5507 54.8052C75.0777 54.8052 75.2746 54.9322 75.2492 55.4846C75.2111 56.5006 75.2492 57.523 75.2492 58.5454C75.2492 58.9962 75.3952 59.1804 75.8842 59.1804C76.9002 59.1804 77.9226 59.1804 78.9386 59.1804C79.3958 59.1804 79.6307 59.2566 79.618 59.8154C79.5799 61.2441 79.618 62.6729 79.618 64.0953C79.618 64.5207 79.472 64.6604 79.0592 64.6541C78.0115 64.6541 76.9637 64.6541 75.916 64.6541C75.4778 64.6541 75.2809 64.7811 75.2809 65.251C75.2809 66.7687 75.2809 68.28 75.2809 69.7976C75.2809 71.3153 75.9922 71.9503 77.4971 72.0519C78.0195 72.0836 78.5432 72.0836 79.0656 72.0519C79.5101 72.0519 79.7006 72.1789 79.7006 72.6488C79.6752 74.0797 79.6752 75.5063 79.7006 76.9287C79.7006 77.3478 79.5609 77.4875 79.1481 77.4812C77.8781 77.4812 76.6081 77.5256 75.3381 77.4812C74.4487 77.4118 73.5853 77.1484 72.8086 76.7096C72.0319 76.2708 71.3607 75.6671 70.8423 74.9412C70.7343 74.7873 70.6688 74.6077 70.652 74.4205C70.6353 74.2333 70.6681 74.0449 70.747 73.8744C71.3616 71.7406 71.5217 69.5016 71.2169 67.3021C71.0188 66.035 70.6224 64.8071 70.0422 63.6635C69.7782 63.1449 69.6473 62.5688 69.6612 61.9871C69.6612 59.8852 69.6612 57.7897 69.6612 55.6942C69.6612 55.1036 69.8009 54.8496 70.4359 54.9004C71.0709 54.9512 71.7821 54.8052 72.4552 54.8052Z'
        fill='#2DB200'
      />
      <path
        d='M14.0988 58.6725C13.2289 57.9867 11 58.5645 11 58.5645C13.6988 59.9361 12.8542 61.6506 15.7054 62.2793C15.7054 62.2856 15.2418 59.5551 14.0988 58.6725ZM12.4288 58.9265C12.9324 58.8695 13.4408 58.9788 13.8766 59.2376C14.3846 59.644 14.7402 61.0664 14.7402 61.0664C13.4448 60.7045 13.7496 59.517 12.4288 58.9265V58.9265Z'
        fill='#2DB200'
      />
      <path
        d='M13.8765 59.2375C13.4408 58.9787 12.9323 58.8694 12.4287 58.9263C13.7495 59.5169 13.4447 60.7043 14.7401 61.0663C14.7401 61.0663 14.3845 59.6439 13.8765 59.2375Z'
        fill='#EAEAEA'
      />
      <path
        d='M133.937 92.9373C133.035 92.9373 132.134 92.9373 131.232 92.9373C130.421 92.9339 129.645 92.6104 129.071 92.0371C128.498 91.4638 128.175 90.6873 128.171 89.8766C128.14 86.5872 128.171 83.2979 128.171 80.0149C128.171 79.1386 128.698 79.3101 129.2 79.2847C129.702 79.2593 130.133 79.2847 130.133 80.0276C130.133 81.9326 130.178 81.9326 132.127 81.9326H133.963V83.8377C132.997 83.8377 132.058 83.8821 131.08 83.8377C130.368 83.8059 130.083 84.0218 130.102 84.7711C130.153 86.3777 130.102 87.9779 130.102 89.5781C130.078 89.7678 130.097 89.9606 130.158 90.1418C130.219 90.3231 130.32 90.4883 130.454 90.6251C130.587 90.7618 130.75 90.8666 130.93 90.9316C131.11 90.9966 131.302 91.0202 131.492 91.0005C132.312 91.0005 133.124 91.0005 133.944 91.0005L133.937 92.9373Z'
        fill='#DADBD9'
      />
      <path
        d='M88.8002 88.6893C88.8002 87.6733 88.8002 86.651 88.8002 85.635C88.7401 84.8712 88.9341 84.109 89.3522 83.467C89.7702 82.825 90.3888 82.3392 91.1116 82.0853C92.6229 81.4503 94.0898 81.5392 95.3153 82.7203C95.6963 83.095 95.8932 83.0696 96.3123 82.7203C98.8523 80.7454 102.059 81.698 102.878 84.6253C102.966 85.0532 102.998 85.4909 102.973 85.9271C102.973 87.9972 102.942 90.0673 102.973 92.1311C102.973 92.8867 102.612 92.9312 102.059 92.8867C101.507 92.8423 100.846 93.0899 100.865 92.1057C100.865 89.9784 100.865 87.8511 100.865 85.7239C100.896 85.216 100.73 84.7156 100.402 84.3272C100.073 83.9388 99.6068 83.6923 99.1007 83.6391C98.5946 83.5859 98.0875 83.73 97.6851 84.0415C97.2828 84.3531 97.0162 84.8079 96.9409 85.3111C96.8023 86.3507 96.7682 87.4016 96.8393 88.448C96.8393 89.6673 96.8076 90.8928 96.8393 92.1184C96.8393 92.7534 96.6298 92.9629 96.0329 92.8931C95.8219 92.8689 95.6089 92.8689 95.3979 92.8931C94.8518 92.9566 94.6994 92.7153 94.7057 92.1882C94.7057 90.0356 94.7057 87.8765 94.7057 85.7239C94.73 85.3564 94.6514 84.9894 94.4786 84.6641C94.3059 84.3388 94.0459 84.0681 93.7278 83.8824C93.4026 83.6796 93.0238 83.5796 92.6409 83.5954C92.258 83.6113 91.8888 83.7423 91.5815 83.9713C90.8068 84.5237 90.7179 85.4 90.6988 86.2382C90.6607 88.194 90.6417 90.1435 90.6988 92.093C90.6988 92.9947 90.2099 92.874 89.6511 92.9058C89.0923 92.9375 88.6795 92.8232 88.724 92.0612C88.851 90.969 88.8002 89.826 88.8002 88.6893Z'
        fill='#DADBD9'
      />
      <path
        d='M104.714 87.3936C104.409 84.4282 107.419 81.6786 110.321 81.7167C113.121 81.7167 116.036 84.1869 115.991 87.3555C115.941 90.1877 113.655 93.1976 110.613 93.1658C107.571 93.1341 104.422 90.6004 104.714 87.3936ZM113.832 87.3936C113.832 85.1775 112.442 83.7297 110.359 83.6662C109.888 83.6661 109.422 83.7593 108.987 83.9402C108.553 84.1212 108.158 84.3863 107.826 84.7204C107.495 85.0545 107.232 85.4509 107.055 85.8868C106.877 86.3227 106.787 86.7895 106.79 87.2603C106.885 89.7559 108.06 91.191 110.334 91.2037C112.607 91.2164 113.82 89.9083 113.832 87.3809V87.3936Z'
        fill='#DADBD9'
      />
      <path
        d='M120.671 92.9309C119.827 92.9309 118.982 92.8928 118.131 92.9309C117.28 92.969 117.452 92.4801 117.496 92.0102C117.541 91.5403 117.274 90.9688 118.131 90.9942C119.528 91.0259 120.932 90.9942 122.329 90.9942C122.589 90.9942 122.856 90.9942 123.116 90.9942C123.637 90.9942 124.151 90.8291 124.285 90.2703C124.418 89.7114 124.031 89.286 123.529 89.0002C122.766 88.6433 121.968 88.3666 121.148 88.1747C120.387 87.9756 119.652 87.6901 118.957 87.3238C117.338 86.4539 117.376 85.4887 117.547 84.0091C117.668 82.9867 118.817 82.1993 120.055 82.0469C121.706 81.8437 123.37 81.9326 125.021 81.8755C125.834 81.8755 125.904 82.2628 125.904 82.9042C125.904 83.5455 125.796 83.8757 125.047 83.8757C123.507 83.7773 121.963 83.8156 120.43 83.99C119.941 84.0726 119.573 84.2758 119.478 84.733C119.382 85.1902 119.732 85.4633 120.113 85.6919C120.807 86.059 121.555 86.314 122.329 86.4475C123.093 86.6289 123.833 86.9018 124.532 87.2603C125.923 88.0096 126.653 89.3368 126.361 90.5878C126.005 92.1054 125.002 92.8611 123.186 92.9246C122.361 92.95 121.516 92.9309 120.671 92.9309Z'
        fill='#DADBD9'
      />
      <path
        d='M70.9765 69.1756C70.8364 65.4258 69.2585 61.8739 66.57 59.2563C63.8815 56.6387 60.2886 55.1564 56.5365 55.1165C54.6776 55.0652 52.8309 55.432 51.1326 56.1897C50.5715 56.4528 50.0246 56.7453 49.4943 57.066L48.7069 57.5423C48.116 57.9023 47.5496 58.3011 47.0115 58.7361C46.5735 59.138 46.0463 59.43 45.4732 59.5879C44.9002 59.7458 44.2978 59.7651 43.7158 59.6441C41.6774 59.1107 39.4359 57.1486 36.2608 57.0343C33.0858 56.92 30.0949 60.9713 28.9964 62.1143C27.3898 63.7272 24.6085 65.5624 19.1729 63.9749C19.1729 63.9749 22.8559 66.2037 26.1071 66.1529C29.3583 66.1021 31.3395 63.0922 34.8194 63.0922C37.08 63.0922 38.4833 65.8608 40.0455 69.5312C40.2804 70.0836 40.6233 70.998 40.8011 71.5632C41.5377 73.9127 44.2365 80.8025 51.1707 83.0949C53.4009 83.977 55.8152 84.2915 58.1969 84.0103C60.5786 83.729 62.8532 82.8608 64.8166 81.4835C66.7799 80.1062 68.3706 78.2629 69.4457 76.1192C70.5209 73.9754 71.0469 71.5982 70.9765 69.201V69.1756ZM56.6635 83.2409C54.9239 83.2421 53.1999 82.9124 51.5835 82.2694V82.2694C44.7127 80.0024 42.2108 72.9856 41.6393 71.0425C41.525 70.6551 41.3726 70.2551 41.2329 69.8995C39.8296 66.3942 38.2928 63.0351 36.3815 62.2223C34.4701 61.4094 32.1079 62.4509 30.241 63.7526C26.9072 66.064 24.0624 65.1433 24.0624 65.1433C27.8724 65.0036 29.6695 62.5652 31.1935 61.0221C32.2349 59.968 34.1653 58.082 36.2037 58.082C39.2263 58.082 40.1725 59.8854 43.4046 60.7808C45.3414 61.3142 47.8878 58.8377 48.091 58.6789C48.091 58.6789 48.5926 58.2916 48.599 58.2979C51.774 56.0945 54.2442 55.4595 57.7113 55.7135C61.2555 55.9984 64.5525 57.6417 66.9138 60.3001C69.275 62.9585 70.5178 66.4264 70.3826 69.9794C70.2473 73.5325 68.7445 76.8959 66.188 79.3671C63.6316 81.8383 60.2191 83.2262 56.6635 83.2409V83.2409Z'
        fill='#E5E5E5'
      />
      <path
        d='M56.7012 82.6564C63.9818 82.6564 69.8839 76.7543 69.8839 69.4737C69.8839 62.1931 63.9818 56.291 56.7012 56.291C49.4206 56.291 43.5186 62.1931 43.5186 69.4737C43.5186 76.7543 49.4206 82.6564 56.7012 82.6564Z'
        fill='#E5E5E5'
      />
      <path
        d='M57.6987 55.7323C54.2316 55.4783 51.7551 56.1133 48.5864 58.3168C48.5864 58.3168 48.0847 58.6978 48.0784 58.6978C47.8752 58.8565 45.3288 61.333 43.392 60.7996C40.1599 59.9043 39.2137 58.1009 36.1911 58.1009C34.1527 58.1009 32.2223 60.0059 31.1809 61.0409C29.6569 62.584 27.8662 65.0224 24.0498 65.1621C24.0498 65.1621 26.8946 66.0829 30.2284 63.7714C32.0953 62.5014 34.375 61.3838 36.3689 62.2411C38.3628 63.0983 39.817 66.4131 41.2203 69.9056C41.36 70.2612 41.5124 70.6612 41.6267 71.0486C42.1982 72.9917 44.7002 80.0085 51.5709 82.2755C53.4174 83.008 55.4003 83.3328 57.3839 83.2276C59.3676 83.1225 61.3051 82.59 63.0638 81.6664C64.8225 80.7429 66.3608 79.4502 67.5734 77.8769C68.7861 76.3035 69.6444 74.4867 70.0896 72.5508C70.5348 70.6149 70.5564 68.6057 70.1529 66.6607C69.7494 64.7157 68.9303 62.8808 67.7518 61.2818C66.5732 59.6827 65.063 58.3573 63.3246 57.3962C61.5862 56.435 59.6606 55.861 57.6797 55.7132L57.6987 55.7323ZM56.7017 82.6565C54.0958 82.6502 51.5502 81.8717 49.3865 80.4194C47.2228 78.967 45.5381 76.906 44.5452 74.4966C43.5523 72.0873 43.2957 69.4377 43.808 66.8826C44.3202 64.3275 45.5782 61.9815 47.4231 60.141C49.268 58.3006 51.617 57.0482 54.1733 56.5421C56.7297 56.036 59.3786 56.2989 61.7856 57.2976C64.1926 58.2963 66.2496 59.986 67.6967 62.1532C69.1438 64.3204 69.9162 66.8678 69.9162 69.4738C69.9162 71.2076 69.5742 72.9245 68.9097 74.5259C68.2452 76.1274 67.2713 77.5821 66.0438 78.8066C64.8164 80.0311 63.3594 81.0015 61.7563 81.6621C60.1532 82.3228 58.4356 82.6607 56.7017 82.6565V82.6565Z'
        fill='#2DB200'
      />
      <path
        d='M59.5457 62.6859L56.9295 66.7816C56.8615 66.8853 56.8291 67.0083 56.8371 67.132C56.8452 67.2557 56.8932 67.3734 56.974 67.4674L60.2696 71.468C60.3497 71.5653 60.4589 71.6345 60.5812 71.6654C60.7034 71.6962 60.8324 71.6871 60.9491 71.6394L65.0893 69.9059C65.1958 69.8611 65.2869 69.7862 65.3512 69.6902C65.4156 69.5943 65.4503 69.4816 65.4513 69.3661V64.6353C65.449 64.5134 65.4095 64.3951 65.3379 64.2963C65.2664 64.1976 65.1663 64.1231 65.0512 64.0829L60.2252 62.4382C60.1005 62.3983 59.9661 62.401 59.8431 62.4459C59.7201 62.4907 59.6155 62.5751 59.5457 62.6859V62.6859Z'
        fill='#161616'
      />
      <path
        d='M52.0908 78.3385V80.7325C52.0915 80.8507 52.1292 80.9658 52.1987 81.0615C52.2682 81.1572 52.3659 81.2287 52.4782 81.2659L56.4533 82.6057C56.5308 82.6309 56.6127 82.6393 56.6937 82.6306C56.7747 82.6218 56.8529 82.596 56.9232 82.5549L60.0982 80.6499C60.1709 80.606 60.2327 80.5463 60.2792 80.4752C60.3256 80.4041 60.3554 80.3235 60.3664 80.2393C60.3774 80.1551 60.3692 80.0695 60.3426 79.9889C60.316 79.9083 60.2715 79.8347 60.2125 79.7736L58.1488 77.6464C58.0916 77.5871 58.0222 77.5411 57.9455 77.5115C57.8687 77.4819 57.7864 77.4694 57.7043 77.4749L52.6242 77.8305C52.4888 77.8361 52.3601 77.8907 52.262 77.9842C52.1638 78.0776 52.103 78.2036 52.0908 78.3385V78.3385Z'
        fill='#161616'
      />
      <path
        d='M69.7446 71.3599L67.8396 73.3919C67.7711 73.4597 67.719 73.5422 67.6872 73.6332L66.0425 77.9576C66.0041 78.0545 65.9847 78.158 65.9854 78.2624V78.8402C67.0266 77.8405 67.8861 76.6673 68.5254 75.3731C69.4906 73.2712 69.7065 72.4457 69.7446 71.3599Z'
        fill='#161616'
      />
      <path
        d='M50.4845 58.1201L50.9607 59.0218C50.9847 59.0671 51.0217 59.104 51.0671 59.1278C51.1124 59.1516 51.1639 59.1611 51.2147 59.1552L56.4535 58.5519C56.5025 58.5397 56.5467 58.5132 56.5805 58.4757L58.5236 56.9581C58.5625 56.9283 58.5917 56.8877 58.6075 56.8414C58.6234 56.795 58.6251 56.745 58.6125 56.6977V56.5707C58.5996 56.5205 58.5723 56.4751 58.5339 56.4401C58.4956 56.4051 58.4478 56.3821 58.3966 56.3739C57.0266 56.2053 55.6388 56.2481 54.2818 56.5009C52.991 56.6965 51.7471 57.1269 50.6114 57.7709C50.58 57.7852 50.5516 57.8057 50.5282 57.8311C50.5047 57.8565 50.4865 57.8863 50.4747 57.9188C50.4628 57.9514 50.4576 57.9859 50.4593 58.0205C50.461 58.055 50.4695 58.0889 50.4845 58.1201V58.1201Z'
        fill='#161616'
      />
      <path
        d='M44.7251 66.1847L47.5889 64.0956C47.6339 64.062 47.6851 64.0378 47.7395 64.0241C47.7938 64.0104 47.8504 64.0076 47.9059 64.0159C47.9614 64.0241 48.0147 64.0433 48.0627 64.0722C48.1107 64.1012 48.1526 64.1393 48.1858 64.1845L50.656 67.4928C50.7074 67.5664 50.7349 67.654 50.7349 67.7437C50.7349 67.8334 50.7074 67.9209 50.656 67.9945L47.8556 72.1347C47.8119 72.2007 47.7508 72.2533 47.6791 72.2869C47.6075 72.3205 47.5279 72.3338 47.4492 72.3252L45.0807 72.0585C44.9942 72.0496 44.9125 72.0143 44.8467 71.9576C44.7808 71.9008 44.734 71.8252 44.7124 71.741L43.944 68.9216C43.9249 68.8487 43.9249 68.7722 43.944 68.6994L44.579 66.4197C44.6006 66.3275 44.6519 66.2449 44.7251 66.1847V66.1847Z'
        fill='#161616'
      />
      <path
        d='M47.6143 62.5652C47.7649 62.2227 47.9478 61.8953 48.1604 61.5873C48.3662 61.2754 48.591 60.9764 48.8335 60.6919C49.0803 60.4102 49.3455 60.145 49.6272 59.8982C49.9122 59.6593 50.2182 59.4468 50.5416 59.2632C50.4019 59.3902 50.2559 59.5045 50.1225 59.6315L49.9193 59.822L49.7225 60.0188C49.4621 60.2792 49.2081 60.5459 48.9668 60.8253C48.7255 61.1047 48.4969 61.3905 48.2683 61.6826C48.154 61.8286 48.0397 61.981 47.9381 62.1334C47.8365 62.2858 47.6905 62.4128 47.6143 62.5652Z'
        fill='#161616'
      />
      <path
        d='M44.782 74.6177C44.6729 74.2032 44.6195 73.7762 44.6232 73.3476C44.6241 73.135 44.6411 72.9227 44.674 72.7126C44.7049 72.4967 44.7559 72.2841 44.8264 72.0776C44.8264 72.1856 44.7883 72.2872 44.782 72.3888V72.5475V72.6999C44.769 72.9114 44.769 73.1235 44.782 73.3349C44.782 73.5381 44.782 73.7477 44.782 73.97C44.7755 74.0736 44.7755 74.1775 44.782 74.2811C44.7629 74.4081 44.7756 74.5097 44.782 74.6177Z'
        fill='#161616'
      />
      <path
        d='M51.6845 80.866C51.4711 80.8574 51.2588 80.8319 51.0495 80.7898C50.6328 80.713 50.2288 80.5783 49.8493 80.3898C49.66 80.2935 49.4794 80.1809 49.3096 80.0532C49.4048 80.0977 49.4937 80.1548 49.5953 80.1929L49.735 80.2564L49.8811 80.3199C50.0716 80.3961 50.2684 80.4723 50.4653 80.5358C50.6621 80.5993 50.8653 80.6628 51.0622 80.7136C51.1622 80.745 51.264 80.7704 51.367 80.7898C51.5003 80.8216 51.5765 80.8406 51.6845 80.866Z'
        fill='#161616'
      />
      <path
        d='M51.4502 67.5308C51.8007 67.4017 52.1619 67.3039 52.5297 67.2387C52.8966 67.1672 53.2678 67.1205 53.641 67.099C54.0149 67.0672 54.3909 67.0672 54.7649 67.099C55.1385 67.1269 55.5085 67.1907 55.8698 67.2895C55.6857 67.2895 55.5015 67.2514 55.3174 67.2514H55.038H54.7586C54.3903 67.2514 54.022 67.2514 53.6537 67.2514C53.2854 67.2514 52.9171 67.3086 52.5488 67.353C52.3646 67.353 52.1805 67.3975 52.0027 67.4292C51.8248 67.461 51.6343 67.4991 51.4502 67.5308Z'
        fill='#161616'
      />
      <path
        d='M65.172 78.9482C64.8603 79.1565 64.5309 79.3371 64.1878 79.488C63.8485 79.6444 63.4983 79.776 63.14 79.8817C62.7802 79.9918 62.4131 80.0766 62.0414 80.1357C61.6716 80.1908 61.2977 80.2141 60.9238 80.2056C61.108 80.1675 61.2921 80.1548 61.4763 80.1103L61.7493 80.0595L62.016 80.0023C62.378 79.9134 62.7336 79.8245 63.0892 79.7166C63.4448 79.6086 63.7877 79.488 64.137 79.361L64.6577 79.1641C64.8355 79.1006 65.0006 79.0244 65.172 78.9482Z'
        fill='#161616'
      />
      <path
        d='M65.8135 70.0771C66.0231 70.2403 66.2185 70.4208 66.3977 70.6169C66.5813 70.8167 66.751 71.0289 66.9057 71.2519C67.0589 71.4663 67.1968 71.6913 67.3184 71.925C67.4422 72.1608 67.5442 72.4074 67.6232 72.6616C67.5534 72.5473 67.5026 72.4267 67.4327 72.3124L67.3375 72.1409L67.2359 71.9758C67.0962 71.7536 66.9565 71.5313 66.8041 71.3408C66.6517 71.1503 66.4929 70.9217 66.3278 70.7058L66.0738 70.401C65.9913 70.274 65.9024 70.1787 65.8135 70.0771Z'
        fill='#161616'
      />
      <path
        d='M56.7012 58.7104C56.8963 58.7689 57.0822 58.8544 57.2536 58.9644C57.4301 59.0662 57.5961 59.1853 57.7489 59.3201C58.0562 59.5846 58.3068 59.9086 58.4855 60.2726L58.0601 59.8535C57.9204 59.7138 57.7743 59.5804 57.6283 59.4534C57.4822 59.3264 57.3235 59.1994 57.1711 59.0724L56.7012 58.7104Z'
        fill='#161616'
      />
      <path
        d='M51.742 77.78C51.0514 77.4624 50.4098 77.0476 49.8369 76.548C49.5502 76.2973 49.2847 76.0233 49.0432 75.7289C48.7995 75.4324 48.6025 75.1005 48.459 74.7446C48.8879 75.3572 49.3961 75.9102 49.9703 76.3893C50.2497 76.6369 50.5482 76.8655 50.8403 77.0941C51.1324 77.3227 51.4372 77.5577 51.742 77.78Z'
        fill='#161616'
      />
      <path
        d='M65.6934 64.0703C65.8208 63.8126 65.9856 63.5751 66.1823 63.3654C66.2824 63.2538 66.3906 63.1498 66.5062 63.0543C66.629 62.9626 66.7609 62.8839 66.8999 62.8193C66.6671 62.9898 66.4541 63.1857 66.2649 63.4035C66.1696 63.5115 66.0744 63.6194 65.9855 63.7337L65.6934 64.0703Z'
        fill='#161616'
      />
      <path
        d='M58.2881 77.3036C58.523 76.8972 58.7961 76.5289 59.0628 76.1542L59.4692 75.5954C59.6025 75.4113 59.7295 75.2208 59.8756 75.0303C59.7649 75.4898 59.5618 75.9219 59.2787 76.3003C59.1408 76.4922 58.9902 76.6747 58.8278 76.8464C58.6625 77.0152 58.4818 77.1683 58.2881 77.3036V77.3036Z'
        fill='#161616'
      />
      <path
        d='M66.7402 60.9077L66.9434 62.3111C66.9548 62.3804 66.9832 62.4459 67.026 62.5016L69.3755 65.7591C69.1292 64.8424 68.7664 63.9611 68.296 63.1366C67.8816 62.3261 67.3581 61.5762 66.7402 60.9077Z'
        fill='#161616'
      />
      <path
        d='M42.8138 59.3202V58.2788C42.8107 58.2535 42.8107 58.2279 42.8138 58.2026C42.8024 57.6962 42.9866 57.205 43.3282 56.831C43.7282 56.469 44.9284 56.5008 46.7826 56.5008C46.8926 56.4991 46.9976 56.4542 47.0748 56.3758C47.1521 56.2974 47.1954 56.1917 47.1953 56.0817V51.5223C47.1959 51.4218 47.1593 51.3247 47.0924 51.2496C47.0256 51.1746 46.9333 51.1269 46.8334 51.1159C45.0681 50.8937 37.4353 50.3349 36.7432 56.9199V57.0723C39.2133 57.3072 41.1183 58.6026 42.8138 59.3202Z'
        fill='#2DB200'
      />
      <path
        d='M40.7818 71.5312C40.604 70.9597 40.2611 70.0453 40.0261 69.4992C38.972 67.01 37.9814 64.9399 36.7559 63.8604V76.7636C36.7558 76.8737 36.7991 76.9794 36.8764 77.0578C36.9536 77.1362 37.0586 77.1811 37.1686 77.1827H42.4011C42.5111 77.1811 42.6161 77.1362 42.6933 77.0578C42.7705 76.9794 42.8138 76.8737 42.8138 76.7636V76.0461C41.9646 74.6246 41.2827 73.1095 40.7818 71.5312V71.5312Z'
        fill='#2DB200'
      />
    </svg>
  );
}
