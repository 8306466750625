import { ReactNode } from 'react';
import OSKarlovyVary from '@amf/shared/icons/os/OSKarlovyVary';
import OSHavlovice from '@amf/shared/icons/os/OSHavlovice';
import OSPKFL from '@amf/shared/icons/os/OSPKFL';
import OSBlanensko from '@amf/shared/icons/os/OSBlanensko';
import OSJihlava from '@amf/shared/icons/os/OSJihlava';
import OSMost from '@amf/shared/icons/os/OSMost';
import OSPribram from '@amf/shared/icons/os/OSPribram';
import OSPardubice from '@amf/shared/icons/os/OSPardubice';
import OSPSMF from '@amf/shared/icons/os/OSPSMF';
import OSLiberec from '@amf/shared/icons/os/OSLiberec';
import OSLitomysl from '@amf/shared/icons/os/OSLitomysl';
import OSKoprivnice from '@amf/shared/icons/os/OSKoprivnice';
import OSKolin from '@amf/shared/icons/os/OSKolin';
import OSOstrava from '@amf/shared/icons/os/OSOstrava';
import OSOlomouc from '@amf/shared/icons/os/OSOlomouc';
import { UnionFragment } from '@amf/shared/types/graphql';
import OSBSMKErb from '@amf/shared/icons/os/OSBSMKErb';
import OSPlzenAlt from '@amf/shared/icons/os/OSPlzenAlt';
import BrandSuperligaAlt from '@amf/shared/icons/brands/BrandSuperligaAlt';
import BrandCNP from '@amf/shared/icons/brands/BrandCNP';

export interface UnionPosition {
  top: number;
  left: number;
}

export interface UnionContent {
  logo?: ReactNode;
  city: string;
  location: {
    xl: UnionPosition;
    lg: UnionPosition;
    md: UnionPosition;
  };
}

export const UnionContentList: { [id: string]: UnionContent } = {
  // Blanensko
  '32369fda-bdb9-4479-8244-4ed4ee4f12f5': {
    logo: <OSBlanensko />,
    city: 'Blansko, Česká Republika',
    location: {
      xl: { top: 348, left: 715 },
      lg: { top: 348, left: 625 },
      md: { top: 258, left: 471 },
    },
  },
  // Brno
  '952bd190-5a28-473c-b845-b2b3a3166a08': {
    logo: <OSBSMKErb />,
    city: 'Brno, Česká Republika',
    location: {
      xl: { top: 385, left: 726 },
      lg: { top: 385, left: 645 },
      md: { top: 289, left: 484 },
    },
  },
  // Havlovice
  'a6ebad74-6a4b-457b-be52-53755d284c34': {
    logo: <OSHavlovice />,
    city: 'Havlovice, Česká Republika',
    location: {
      xl: { top: 113, left: 616 },
      lg: { top: 113, left: 525 },
      md: { top: 85, left: 400 },
    },
  },
  // Jihlava
  '766e9c29-8c89-4a0b-84ea-4d053f7d8d86': {
    logo: <OSJihlava />,
    city: 'Jihlava, Česká Republika',
    location: {
      xl: { top: 361, left: 552 },
      lg: { top: 361, left: 462 },
      md: { top: 265, left: 355 },
    },
  },
  // Karlovy Vary
  '5d21cf6c-42d7-4d77-aa13-0dbc24a7bd37': {
    logo: <OSKarlovyVary />,
    city: 'Karlovy Vary, Česká Republika',
    location: {
      xl: { top: 166, left: 157 },
      lg: { top: 166, left: 67 },
      md: { top: 126, left: 47 },
    },
  },
  // Kolín
  'db2004a9-754a-40f4-8f4e-1f77e3f755a3': {
    logo: <OSKolin />,
    city: 'Kolín, Česká Republika',
    location: {
      xl: { top: 200, left: 497 },
      lg: { top: 200, left: 407 },
      md: { top: 150, left: 307 },
    },
  },
  // Kopřivnice
  '0bbdbf79-99e2-4f96-8b83-6f074e9355d9': {
    logo: <OSKoprivnice />,
    city: 'Kopřivnice, Česká Republika',
    location: {
      xl: { top: 280, left: 950 },
      lg: { top: 280, left: 860 },
      md: { top: 210, left: 660 },
    },
  },
  // Liberec
  'fd35ea21-b5eb-4fe6-9aa3-b4ae9d496e4b': {
    logo: <OSLiberec />,
    city: 'Liberec, Česká Republika',
    location: {
      xl: { top: 55, left: 490 },
      lg: { top: 55, left: 400 },
      md: { top: 38, left: 300 },
    },
  },
  // Litomyšl
  '00b94c57-da51-4aff-bd4b-8b9a74cb0c2c': {
    logo: <OSLitomysl />,
    city: 'Litomyšl, Česká Republika',
    location: {
      xl: { top: 234, left: 680 },
      lg: { top: 234, left: 590 },
      md: { top: 180, left: 450 },
    },
  },
  // Most
  '5d615ebc-3521-448f-8d54-3242581aa448': {
    logo: <OSMost />,
    city: 'Most, Česká Republika',
    location: {
      xl: { top: 109, left: 290 },
      lg: { top: 109, left: 200 },
      md: { top: 85, left: 150 },
    },
  },
  // Olomouc
  '2c1ef8d0-9d62-47a5-986d-860afe92a986': {
    logo: <OSOlomouc />,
    city: 'Olomouc, Česká Republika',
    location: {
      xl: { top: 295, left: 800 },
      lg: { top: 295, left: 710 },
      md: { top: 220, left: 550 },
    },
  },
  // Ostrava
  'c6bc868b-b3f8-4c4b-a721-f2e1e6f844bc': {
    logo: <OSOstrava />,
    city: 'Ostrava, Česká Republika',
    location: {
      xl: { top: 245, left: 930 },
      lg: { top: 245, left: 840 },
      md: { top: 180, left: 640 },
    },
  },
  // Pardubice
  '04f45ad8-c923-4dfe-a631-46bb0c722b42': {
    logo: <OSPardubice />,
    city: 'Pardubice, Česká Republika',
    location: {
      xl: { top: 215, left: 590 },
      lg: { top: 215, left: 500 },
      md: { top: 165, left: 390 },
    },
  },
  // Plzeň
  '5617db91-e3a6-491d-b342-334113703d7a': {
    logo: <OSPlzenAlt />,
    city: 'Plzeň, Česká Republika',
    location: {
      xl: { top: 270, left: 245 },
      lg: { top: 270, left: 155 },
      md: { top: 205, left: 115 },
    },
  },
  // Praha - PKFL
  '0919d34e-3a3b-494b-8a3e-3919cfb2662e': {
    logo: <OSPKFL />,
    city: 'Praha, Česká Republika',
    location: {
      xl: { top: 215, left: 420 },
      lg: { top: 215, left: 330 },
      md: { top: 165, left: 250 },
    },
  },
  // Praha - PSMF
  '7a88cc06-0d4e-4593-8d35-1ec291402a66': {
    logo: <OSPSMF />,
    city: 'Praha, Česká Republika',
    location: {
      xl: { top: 185, left: 400 },
      lg: { top: 185, left: 310 },
      md: { top: 140, left: 225 },
    },
  },
  // Příbram
  'e5c82183-16f5-4f93-838d-edff6ae68b9f': {
    logo: <OSPribram />,
    city: 'Příbram, Česká Republika',
    location: {
      xl: { top: 280, left: 345 },
      lg: { top: 280, left: 255 },
      md: { top: 215, left: 195 },
    },
  },
  // Superliga
  '9a134ed7-55a0-41b7-985b-a4bef64b4db6': {
    logo: <BrandSuperligaAlt />,
    city: 'Česká Republika',
    location: {
      xl: { top: 0, left: 0 },
      lg: { top: 0, left: 0 },
      md: { top: 0, left: 0 },
    },
  },
  // ČNP
  'f91c7c08-41e2-48a0-a773-866d3063fd67': {
    logo: <BrandCNP className='!w-24' />,
    city: 'Česká Republika',
    location: {
      xl: { top: 0, left: 0 },
      lg: { top: 0, left: 0 },
      md: { top: 0, left: 0 },
    },
  },
};

export function getUnionContent(id: string): UnionContent | undefined {
  if (!UnionContentList[id]) {
    return undefined;
  }

  return UnionContentList[id];
}

export function getPlatformLink(union: UnionFragment, path: string): URL | null {
  if (union.domain.includes('vercel')) return null;
  return new URL(path, `https://${union.domain}`);
}
