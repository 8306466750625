export default function OSBSMKErb() {
  return (
    <svg
      width='262'
      height='320'
      viewBox='0 0 262 320'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_607_2)'>
        <path
          d='M258.487 3.76917H3.70369V133.44H3.6936V226.905L131.146 315.595L258.598 226.905L258.487 3.76917ZM249.041 138.734H248.97L249.011 221.511L131.166 303.514L131.146 303.53L13.281 221.505V138.734H13.271V133.44H13.2911V13.8277H248.905L248.915 33.4578H249.041V138.734Z'
          fill='#BA2C1B'
        />
        <path
          d='M258.487 3.76917H3.70369V133.44H3.6936V226.905L131.146 315.595L258.598 226.905L258.487 3.76917ZM249.041 138.734H248.97L249.011 221.511L131.166 303.514L131.146 303.53L13.281 221.505V138.734H13.271V133.44H13.2911V13.8277H248.905L248.915 33.4578H249.041V138.734Z'
          fill='#BA2C1B'
        />
        <path
          d='M131.166 133.44V303.514L131.146 303.53L13.281 221.505V133.44H131.166Z'
          fill='#BA2C1B'
        />
        <path
          d='M249.041 33.458V138.734H248.971L248.965 133.44L248.945 93.0999H140.617V62.6119H248.93L248.915 33.458H249.041Z'
          fill='#BA2C1B'
        />
        <path
          d='M248.965 133.44H131.166V13.8279H140.617V33.458H248.915L248.93 62.6119H140.617V93.0999H248.945L248.965 133.44Z'
          fill='#BA2C1B'
        />
        <path d='M249.041 62.6119H140.617V93.0999H249.041V62.6119Z' fill='white' />
        <path d='M248.915 33.458H140.617V13.8279H248.905L248.915 33.458Z' fill='#BA2C1B' />
        <path d='M248.915 33.458H140.617V13.8279H248.905L248.915 33.458Z' fill='white' />
        <path d='M131.146 13.8279H13.2912V133.44H131.146V13.8279Z' fill='white' />
        <path d='M249.011 221.511L131.166 303.515V133.44H248.965L249.011 221.511Z' fill='white' />
        <path
          d='M0 229.034V128.416L0.0101019 126.844V0H262.065L262.181 229.028L131.146 320L0 229.034ZM3.49689 133.657V227.059L131.146 315.59L258.684 227.059L258.573 3.66873H3.50699V132.085L3.49689 133.657Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_607_2'>
          <rect width='262' height='320' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
