export default function OSPSMF() {
  return (
    <svg
      width='144'
      height='144'
      viewBox='0 0 144 144'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.86213 81.7086C8.10355 81.7086 10.0727 83.4022 10.0727 86.2222C10.0727 89.0423 8.10355 90.7764 4.86213 90.7764H2.68227V95.2415H0V81.7086H4.86213ZM4.8054 88.2562C6.42611 88.2562 7.29319 87.4459 7.29319 86.2222C7.29319 84.9986 6.34508 84.2207 4.8054 84.2207H2.68227V88.2724L4.8054 88.2562Z'
        fill='black'
      />
      <path
        d='M19.5051 95.2253L16.8229 90.6063H14.5863V95.2253H11.9364V81.7086H16.6689C19.9914 81.7086 21.9767 83.4994 21.9767 86.2222C22.0195 87.0534 21.7978 87.8767 21.3436 88.5741C20.8894 89.2714 20.2259 89.807 19.4484 90.1038L22.5359 95.2253H19.5051ZM14.643 88.2157H17.0741C17.3471 88.2331 17.6208 88.1943 17.8782 88.1018C18.1357 88.0093 18.3715 87.865 18.571 87.6778C18.7705 87.4906 18.9295 87.2645 19.0382 87.0135C19.1469 86.7624 19.2029 86.4918 19.2029 86.2182C19.2029 85.9446 19.1469 85.6739 19.0382 85.4229C18.9295 85.1718 18.7705 84.9457 18.571 84.7585C18.3715 84.5714 18.1357 84.427 17.8782 84.3345C17.6208 84.242 17.3471 84.2033 17.0741 84.2207H14.5863L14.643 88.2157Z'
        fill='black'
      />
      <path
        d='M33.0626 92.6321H27.2118L26.207 95.2253H23.314L28.873 81.7086H31.4013L36.9604 95.2253H34.035L33.0626 92.6321ZM32.1388 90.2578L30.7936 86.8462C30.5099 86.0926 30.1615 84.9662 30.1372 84.9662C30.1372 84.9662 29.7725 86.0926 29.4808 86.8462L28.1437 90.2578H32.1388Z'
        fill='black'
      />
      <path
        d='M49.4965 84.2207L43.0137 91.5139C42.4302 92.1541 41.8549 92.7294 41.8549 92.7537C41.8549 92.7537 42.722 92.697 43.824 92.697H49.4965V95.2091H38.2002V92.697L44.9018 85.2985C45.3475 84.7961 46.0768 84.2045 46.0768 84.1802C46.0768 84.1802 44.9747 84.1802 44.0915 84.1802H38.419V81.6681H49.5208L49.4965 84.2207ZM43.9942 81.2386L47.5841 78.1755L46.2956 76.8384L43.9942 78.7508L41.6928 76.8384L40.38 78.1755L43.9942 81.2386Z'
        fill='black'
      />
      <path
        d='M58.767 85.8171C58.6278 85.2175 58.2836 84.6854 57.7939 84.3126C57.3042 83.9397 56.6997 83.7496 56.0847 83.775C54.853 83.775 54.0588 84.3746 54.0588 85.185C54.0588 86.2628 55.5823 86.6517 57.3002 87.2271C59.2694 87.9078 61.5303 88.945 61.5303 91.3599C61.5303 93.7748 59.4396 95.4927 56.3521 95.4927C55.1072 95.5479 53.8807 95.1764 52.8757 94.4395C51.8706 93.7027 51.1473 92.6448 50.8255 91.4409L53.4024 90.7764C53.5636 91.4589 53.9542 92.0654 54.5087 92.4945C55.0633 92.9237 55.7483 93.1497 56.4494 93.1346C57.8999 93.1346 58.6535 92.4134 58.6535 91.5139C58.6535 90.1444 56.8221 89.8202 54.9097 89.1395C53.289 88.5642 51.2955 87.7295 51.2955 85.3795C51.2955 83.2726 53.3781 81.4736 56.2387 81.4736C57.3727 81.4316 58.4889 81.7645 59.4147 82.4208C60.3405 83.077 61.0242 84.02 61.3601 85.104L58.767 85.8171Z'
        fill='black'
      />
      <path
        d='M72.2917 95.2253L67.8672 89.1557L66.3599 90.6549V95.2172H63.6776V81.7086H66.3599V84.3746C66.3599 86.595 66.3599 87.4054 66.3599 87.4054C66.3599 87.4054 67.0325 86.6679 68.2886 85.4119L72 81.7086H75.2981L69.6256 87.381L75.4602 95.2253H72.2917Z'
        fill='black'
      />
      <path
        d='M89.2443 81.7086L84.0338 89.0017V95.201H81.3677V89.0261L76.1733 81.7329H79.1797L81.9674 85.6145C82.332 86.1493 82.6805 86.6193 82.6967 86.6193C82.6967 86.6193 83.0613 86.1493 83.426 85.6145L86.1893 81.7329L89.2443 81.7086ZM85.849 79.1154L81.2461 81.1089L80.4358 79.6746L84.8927 77.284L85.849 79.1154Z'
        fill='black'
      />
      <path
        d='M102.745 85.817C102.604 85.2182 102.26 84.687 101.77 84.3144C101.281 83.9419 100.677 83.7511 100.063 83.7749C98.8227 83.7749 98.0285 84.3746 98.0285 85.185C98.0285 86.2627 99.5601 86.6517 101.27 87.2271C103.247 87.9078 105.5 88.945 105.5 91.3599C105.5 93.7747 103.417 95.4927 100.33 95.4927C99.0847 95.5494 97.8575 95.1784 96.8521 94.4414C95.8468 93.7043 95.1239 92.6455 94.8033 91.4409L97.3884 90.744C97.5513 91.4271 97.9433 92.0337 98.4992 92.4628C99.0551 92.8919 99.7413 93.1176 100.443 93.1021C101.894 93.1021 102.648 92.3809 102.648 91.4814C102.648 90.1119 100.808 89.7878 98.8956 89.1071C97.2749 88.5317 95.2895 87.6971 95.2895 85.347C95.2895 83.2401 97.3722 81.4411 100.233 81.4411C101.37 81.4002 102.489 81.7376 103.414 82.4003C104.339 83.0631 105.019 84.0139 105.346 85.1039L102.745 85.817Z'
        fill='black'
      />
      <path
        d='M119.527 81.7086L114.139 95.2253H111.278L105.897 81.7086H108.831L111.934 89.8931C112.404 91.2221 112.688 92.2351 112.745 92.2351C112.745 92.2351 113.036 91.2221 113.555 89.8931L116.642 81.7086H119.527Z'
        fill='black'
      />
      <path
        d='M127.566 92.6321H121.707L120.743 95.2253H117.842L123.401 81.7086H125.937L131.496 95.2253H128.595L127.566 92.6321ZM126.634 90.2578L125.305 86.8462C125.013 86.0926 124.665 84.9662 124.649 84.9662C124.649 84.9662 124.284 86.0926 123.992 86.8462L122.663 90.2578H126.634Z'
        fill='black'
      />
      <path
        d='M144 84.2207L137.517 91.5139C136.942 92.1541 136.358 92.7294 136.358 92.7537C136.358 92.7537 137.234 92.697 138.328 92.697H144V95.2091H132.704V92.697L139.381 85.3147C139.819 84.8123 140.556 84.2207 140.556 84.1964C140.556 84.1964 139.462 84.1964 138.571 84.1964H132.898V81.6843H144V84.2207Z'
        fill='black'
      />
      <path
        d='M10.251 101.862V113.207H9.32718V107.462C9.32718 105.971 9.40822 103.467 9.39201 103.467C9.39201 103.467 8.88959 104.342 8.41958 105.185L5.38075 110.436H4.86213L1.85571 105.185C1.38571 104.374 0.899494 103.467 0.883286 103.467C0.883286 103.467 0.948115 105.971 0.948115 107.462V113.223H0V101.878H0.883286L3.8897 107.04C4.91075 108.774 5.10523 109.277 5.12144 109.277C5.12144 109.277 5.33213 108.774 6.36128 107.04L9.3596 101.862H10.251Z'
        fill='black'
      />
      <path
        d='M20.5506 109.917H14.5864L13.144 113.223H12.0905L17.139 101.878H17.9494L22.9979 113.223H21.993L20.5506 109.917ZM20.1778 109.107L18.079 104.317C17.8359 103.734 17.5766 103.005 17.5604 103.005C17.5604 103.005 17.2849 103.734 17.0418 104.317L14.943 109.107H20.1778Z'
        fill='black'
      />
      <path d='M32.0738 112.332V113.223H24.8536V101.878H25.7774V112.332H32.0738Z' fill='black' />
      <path
        d='M41.4739 112.332V113.223H34.0349V101.878H41.4172V102.77H34.9344V106.732H40.145V107.591H34.9182V112.348L41.4739 112.332ZM36.6118 101.198L36.2877 100.622L39.6507 98.807L40.0234 99.5039L36.6118 101.198Z'
        fill='black'
      />
      <path
        d='M52.6731 101.862V113.207H51.725V107.737H44.9666V113.223H44.0428V101.878H44.9666V106.862H51.725V101.862H52.6731Z'
        fill='black'
      />
      <path
        d='M60.7766 101.635C61.9453 101.634 63.0883 101.979 64.0608 102.627C65.0334 103.275 65.7918 104.197 66.2402 105.276C66.6885 106.356 66.8067 107.544 66.5797 108.69C66.3526 109.837 65.7906 110.89 64.9648 111.717C64.1389 112.544 63.0863 113.107 61.9402 113.336C60.794 113.565 59.6058 113.448 58.5259 113.001C57.446 112.554 56.5229 111.797 55.8734 110.826C55.2239 109.854 54.8772 108.711 54.8772 107.543C54.863 106.764 55.0058 105.99 55.2971 105.267C55.5884 104.544 56.0223 103.888 56.5728 103.337C57.1234 102.785 57.7794 102.35 58.5016 102.058C59.2238 101.766 59.9976 101.622 60.7766 101.635ZM60.7766 112.543C61.7681 112.555 62.7411 112.273 63.5718 111.732C64.4025 111.19 65.0535 110.414 65.4419 109.501C65.8302 108.589 65.9386 107.582 65.753 106.608C65.5675 105.633 65.0965 104.737 64.3999 104.031C63.7033 103.325 62.8126 102.842 61.841 102.644C60.8693 102.446 59.8607 102.541 58.9433 102.918C58.0259 103.294 57.2412 103.935 56.6889 104.758C56.1366 105.582 55.8416 106.551 55.8415 107.543C55.8229 108.199 55.9373 108.852 56.1778 109.463C56.4184 110.074 56.7802 110.629 57.2413 111.097C57.7024 111.564 58.2534 111.933 58.861 112.181C59.4686 112.43 60.1202 112.553 60.7766 112.543Z'
        fill='black'
      />
      <path
        d='M74.034 102.753V107.04H79.0825V107.899H74.034V113.223H73.1021V101.878H80.3305V102.77L74.034 102.753Z'
        fill='black'
      />
      <path
        d='M87.2346 101.635C88.4034 101.634 89.5463 101.979 90.5189 102.627C91.4914 103.275 92.2498 104.197 92.6982 105.276C93.1466 106.356 93.2647 107.544 93.0377 108.69C92.8107 109.837 92.2487 110.89 91.4228 111.717C90.597 112.544 89.5444 113.107 88.3982 113.336C87.2521 113.565 86.0638 113.448 84.9839 113.001C83.904 112.554 82.9809 111.797 82.3314 110.826C81.6819 109.854 81.3353 108.711 81.3353 107.543C81.3221 106.764 81.4657 105.991 81.7574 105.268C82.0491 104.546 82.4831 103.89 83.0334 103.339C83.5837 102.788 84.2392 102.353 84.961 102.061C85.6827 101.768 86.4559 101.623 87.2346 101.635ZM87.2346 112.543C88.2259 112.554 89.1981 112.27 90.0277 111.728C90.8574 111.185 91.507 110.408 91.8942 109.496C92.2813 108.583 92.3885 107.576 92.202 106.603C92.0155 105.629 91.5439 104.733 90.8469 104.028C90.15 103.323 89.2592 102.841 88.2878 102.643C87.3164 102.446 86.3082 102.542 85.3913 102.918C84.4744 103.295 83.6902 103.936 83.1382 104.759C82.5863 105.583 82.2915 106.551 82.2915 107.543C82.2728 108.2 82.3874 108.853 82.6285 109.465C82.8695 110.076 83.232 110.632 83.694 111.1C84.156 111.567 84.708 111.936 85.3165 112.184C85.9251 112.432 86.5776 112.554 87.2346 112.543Z'
        fill='black'
      />
      <path
        d='M102.494 101.862V102.753H98.5959V113.223H97.6559V102.753H93.7743V101.862H102.494Z'
        fill='black'
      />
      <path
        d='M108.588 101.862C110.532 101.862 111.829 102.964 111.829 104.601C111.803 105.167 111.59 105.708 111.224 106.139C110.857 106.57 110.357 106.867 109.803 106.983C110.537 107.074 111.216 107.417 111.725 107.954C112.234 108.49 112.54 109.187 112.591 109.925C112.591 111.919 111.181 113.248 108.928 113.248H104.536V101.903L108.588 101.862ZM108.417 106.667C109.916 106.667 110.848 105.744 110.848 104.666C110.848 103.467 109.876 102.689 108.417 102.689H105.451V106.667H108.417ZM108.912 112.397C109.257 112.429 109.605 112.39 109.934 112.28C110.263 112.17 110.565 111.993 110.822 111.759C111.078 111.526 111.283 111.241 111.423 110.924C111.563 110.607 111.635 110.264 111.634 109.917C111.612 109.567 111.52 109.225 111.365 108.911C111.209 108.597 110.993 108.317 110.728 108.087C110.464 107.858 110.156 107.683 109.823 107.572C109.491 107.462 109.14 107.419 108.79 107.445H105.451V112.397H108.912Z'
        fill='black'
      />
      <path
        d='M121.894 109.917H115.881L114.438 113.223H113.45L118.506 101.878H119.317L124.365 113.223H123.344L121.894 109.917ZM121.521 109.107L119.43 104.317C119.187 103.734 118.928 103.005 118.911 103.005C118.911 103.005 118.636 103.734 118.393 104.317L116.294 109.107H121.521Z'
        fill='black'
      />
      <path d='M133.425 112.332V113.223H126.196V101.878H127.128V112.332H133.425Z' fill='black' />
      <path
        d='M143.984 101.862V108.604C143.984 111.756 142.363 113.466 139.413 113.466C136.464 113.466 134.851 111.765 134.851 108.604V101.862H135.775V108.645C135.775 111.262 137.015 112.575 139.413 112.575C141.812 112.575 143.036 111.262 143.036 108.645V101.862H143.984Z'
        fill='black'
      />
      <path
        d='M9.77308 66.701C9.77308 67.6626 9.48793 68.6026 8.95367 69.4022C8.41941 70.2018 7.66005 70.825 6.77161 71.193C5.88318 71.561 4.90556 71.6573 3.9624 71.4697C3.01924 71.282 2.1529 70.819 1.47291 70.139C0.792934 69.459 0.329861 68.5927 0.142255 67.6495C-0.0453515 66.7063 0.0509348 65.7287 0.418938 64.8403C0.786941 63.9519 1.41013 63.1925 2.2097 62.6582C3.00928 62.124 3.94932 61.8388 4.91096 61.8388C6.20047 61.8388 7.43718 62.3511 8.349 63.2629C9.26083 64.1747 9.77308 65.4114 9.77308 66.701Z'
        fill='#FCB816'
      />
      <path
        d='M43.3298 66.701C43.3298 67.6626 43.0447 68.6026 42.5104 69.4022C41.9762 70.2018 41.2168 70.825 40.3284 71.193C39.4399 71.561 38.4623 71.6573 37.5192 71.4697C36.576 71.282 35.7096 70.819 35.0297 70.139C34.3497 69.459 33.8866 68.5927 33.699 67.6495C33.5114 66.7063 33.6077 65.7287 33.9757 64.8403C34.3437 63.9519 34.9669 63.1925 35.7665 62.6582C36.566 62.124 37.5061 61.8388 38.4677 61.8388C39.7572 61.8388 40.9939 62.3511 41.9058 63.2629C42.8176 64.1747 43.3298 65.4114 43.3298 66.701Z'
        fill='#FCB816'
      />
      <path
        d='M76.8866 66.701C76.8866 67.6626 76.6014 68.6026 76.0672 69.4022C75.5329 70.2018 74.7736 70.825 73.8851 71.193C72.9967 71.561 72.0191 71.6573 71.0759 71.4697C70.1327 71.282 69.2664 70.819 68.5864 70.139C67.9064 69.459 67.4434 68.5927 67.2558 67.6495C67.0681 66.7063 67.1644 65.7287 67.5324 64.8403C67.9004 63.9519 68.5236 63.1925 69.3232 62.6582C70.1228 62.124 71.0628 61.8388 72.0245 61.8388C73.314 61.8388 74.5507 62.3511 75.4625 63.2629C76.3743 64.1747 76.8866 65.4114 76.8866 66.701Z'
        fill='#FCB816'
      />
      <path
        d='M110.443 66.701C110.443 67.6626 110.158 68.6026 109.624 69.4022C109.09 70.2018 108.33 70.825 107.442 71.193C106.553 71.561 105.576 71.6573 104.633 71.4697C103.69 71.282 102.823 70.819 102.143 70.139C101.463 69.459 101 68.5927 100.813 67.6495C100.625 66.7063 100.721 65.7287 101.089 64.8403C101.457 63.9519 102.08 63.1925 102.88 62.6582C103.68 62.124 104.62 61.8388 105.581 61.8388C106.871 61.8388 108.107 62.3511 109.019 63.2629C109.931 64.1747 110.443 65.4114 110.443 66.701Z'
        fill='#FCB816'
      />
      <path
        d='M144 66.701C144 67.6626 143.715 68.6026 143.181 69.4022C142.646 70.2018 141.887 70.825 140.999 71.193C140.11 71.561 139.133 71.6573 138.189 71.4697C137.246 71.282 136.38 70.819 135.7 70.139C135.02 69.459 134.557 68.5927 134.369 67.6495C134.182 66.7063 134.278 65.7287 134.646 64.8403C135.014 63.9519 135.637 63.1925 136.437 62.6582C137.236 62.124 138.176 61.8388 139.138 61.8388C140.427 61.8388 141.664 62.3511 142.576 63.2629C143.488 64.1747 144 65.4114 144 66.701Z'
        fill='#FCB816'
      />
      <path
        d='M76.8866 34.8621C76.8866 35.8238 76.6014 36.7638 76.0672 37.5634C75.5329 38.363 74.7736 38.9861 73.8851 39.3541C72.9967 39.7221 72.0191 39.8184 71.0759 39.6308C70.1327 39.4432 69.2664 38.9802 68.5864 38.3002C67.9064 37.6202 67.4434 36.7538 67.2558 35.8107C67.0681 34.8675 67.1644 33.8899 67.5324 33.0015C67.9004 32.113 68.5236 31.3537 69.3232 30.8194C70.1228 30.2852 71.0628 30 72.0245 30C73.314 30 74.5507 30.5123 75.4625 31.4241C76.3743 32.3359 76.8866 33.5726 76.8866 34.8621Z'
        fill='#FCB816'
      />
    </svg>
  );
}
